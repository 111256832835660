import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_CURRENT_TASK,
  CLEAR_FILTERED_TASKS,
  CLEAR_PROJECT_TASKS,
  CREATE_PROJECT_TASK_SUCCESS,
  DELETE_PROJECT_TASK_SUCCESS,
  EDIT_PROJECT_TASK_FAILURE,
  GET_ALL_BACKLOG_TASKS_SUCCESS,
  GET_ALL_FILTERED_PROJECT_TASKS_SUCCESS,
  GET_ALL_PROJECT_TASKS_SUCCESS,
  GET_TASK_BY_ID_SUCCESS,
  GET_TASK_BY_NUMBER_SUCCESS,
  SORT_PROJECT_TASKS,
  UPDATE_BACKLOG_TASKS_AFTER_TIMELOG_SUCCESS,
  UPDATE_COLUMN_ORDER,
  UPDATE_PROJECT_TASKS_AFTER_TIMELOG_SUCCESS,
} from '../actions/projectTasks';

const initialState = {
  projectTasks: [],
  filteredProjectTasks: [],
  projectBacklog: [],
  currentTask: null,
};

const handlers = {
  [CREATE_PROJECT_TASK_SUCCESS]: (prevState, { payload }) => {
    const newProjectTasks = [...prevState.projectTasks, payload];
    return {
      ...prevState,
      projectTasks: newProjectTasks,
    };
  },
  [GET_ALL_PROJECT_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectTasks: payload?.data,
      columnCount: payload?.data?.length,
    };
  },

  [GET_ALL_FILTERED_PROJECT_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      filteredProjectTasks: payload?.data,
      columnCount: payload?.data?.length,
    };
  },

  [GET_TASK_BY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      currentTask: payload,
    };
  },

  [GET_TASK_BY_NUMBER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      currentTask: payload,
    };
  },

  [GET_ALL_BACKLOG_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectBacklog: payload.data,
    };
  },

  [UPDATE_PROJECT_TASKS_AFTER_TIMELOG_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectTasks: payload,
    };
  },

  [UPDATE_BACKLOG_TASKS_AFTER_TIMELOG_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectBacklog: payload,
    };
  },

  [EDIT_PROJECT_TASK_FAILURE]: (prevState) => {
    return {
      ...prevState,
    };
  },

  [DELETE_PROJECT_TASK_SUCCESS]: (prevState, { payload }) => {
    const updatedProjectTasks = prevState.projectTasks.map((column) => ({
      ...column,
      columnTasks: column.columnTasks.filter((task) => task._id !== payload._id),
    }));

    const updatedBacklog = prevState.projectBacklog.filter((task) => task._id !== payload._id);

    return {
      ...prevState,
      projectTasks: updatedProjectTasks,
      projectBacklog: updatedBacklog,
    };
  },

  [SORT_PROJECT_TASKS]: (prevState, { payload }) => {
    const {
      columnsTasks,
      sourceColumn,
      sourceColumnIndex,
      destinationColumnIndex,
      previousColumnTasks,
      nextColumnTasks,
    } = payload;
    const { destination, source } = payload.result;

    if (sourceColumnIndex === destinationColumnIndex) {
      const { columnTasks } = sourceColumn;
      const [selectedTask] = columnTasks.splice(source.index, 1);
      columnTasks.splice(destination.index, 0, selectedTask);
    } else {
      const [selectedTask] = previousColumnTasks.splice(source.index, 1);
      nextColumnTasks.splice(destination.index, 0, selectedTask);
    }
    return {
      ...prevState,
      projectTasks: columnsTasks,
    };
  },

  [UPDATE_COLUMN_ORDER]: (prevState, { payload }) => {
    const { source, destination, draggableId } = payload.result;
    const { projectTasks } = payload;

    const newProjectTasks = JSON.parse(JSON.stringify(projectTasks));
    const draggableColumn = newProjectTasks.find((column) => column.column._id === draggableId);

    newProjectTasks.splice(source.index, 1);
    newProjectTasks.splice(destination.index, 0, draggableColumn);

    return {
      ...prevState,
      projectTasks: newProjectTasks,
    };
  },

  [CLEAR_PROJECT_TASKS]: (prevState) => {
    return {
      ...prevState,
      projectBacklog: [],
      projectTasks: [],
    };
  },

  [CLEAR_FILTERED_TASKS]: (prevState) => {
    return {
      ...prevState,
      filteredProjectTasks: [],
    };
  },

  [CLEAR_CURRENT_TASK]: (prevState) => {
    return {
      ...prevState,
      currentTask: null,
    };
  },
};

export default createReducer(initialState, handlers);
