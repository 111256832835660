import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_COMMENTS_FOR_TASKS_SUCCESS = createAction('projectComments/GET_COMMENTS_FOR_TASKS.SUCCESS');
export const getCommentsForTask = (tasksId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({ method: 'GET', endpoint: `task-comments/${tasksId}` });
    dispatch(GET_COMMENTS_FOR_TASKS_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_COMMENTS_FOR_TASKS_SUCCESS = createAction('projectComments/CREATE_COMMENTS_FOR_TASKS.SUCCESS');
export const CREATE_COMMENTS_FOR_TASKS_FAILURE = createAction('projectComments/CREATE_COMMENTS_FOR_TASKS.FAILURE');
export const createCommentForTask =
  (userId, projectId, description, tasksId, onSuccess, onFailure) => async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `task-comments/`,
        query: {
          userId: userId,
          projectId: projectId,
          description: description,
          tasksId: tasksId,
        },
      });
      dispatch(CREATE_COMMENTS_FOR_TASKS_SUCCESS(data));
      onSuccess();
    } catch (err) {
      dispatch(CREATE_COMMENTS_FOR_TASKS_FAILURE(err));
      onFailure(err?.response?.data?.error);
    }
  };

export const UPDATE_COMMENTS_FOR_TASKS_SUCCESS = createAction('projectComments/UPDATE_COMMENTS_FOR_TASKS.SUCCESS');
export const UPDATE_COMMENTS_FOR_TASKS_FAILURE = createAction('projectComments/UPDATE_COMMENTS_FOR_TASKS.FAILURE');
export const updateCommentForTask = (description, taskId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `task-comments/${taskId}`,
      query: { description },
    });
    dispatch(UPDATE_COMMENTS_FOR_TASKS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_COMMENTS_FOR_TASKS_FAILURE(err));
    onFailure(err?.response?.data?.err);
  }
};

export const DELETE_COMMENTS_FOR_TASKS_SUCCESS = createAction('projectComments/DELETE_COMMENTS_FOR_TASKS.SUCCESS');
export const DELETE_COMMENTS_FOR_TASKS_FAILURE = createAction('projectComments/DELETE_COMMENTS_FOR_TASKS.FAILURE');
export const deleteCommentForTask = (taskId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `task-comments/${taskId}`,
    });
    dispatch(DELETE_COMMENTS_FOR_TASKS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_COMMENTS_FOR_TASKS_FAILURE(err));
    onFailure(err?.response?.data?.err);
  }
};

export const CLEAR_COMMENTS_FOR_TASKS = createAction('projectComments/CLEAR_COMMENTS_FOR_TASKS');
export const clearCommentsForTasks = () => async (dispatch) => {
  dispatch(CLEAR_COMMENTS_FOR_TASKS());
};
