import { takeLatest, all, call } from 'redux-saga/effects';
import { store } from '../../../index';
import { TechStacksActions, TechStacksHandlers } from 'Redux/actions/techStacks.action';

function* onGetTechStacks() {
  yield call(TechStacksHandlers.getAll(), store.dispatch);
}

export function* techStacksSaga() {
  yield all([
    takeLatest(TechStacksActions.CREATE_SUCCESS, onGetTechStacks),
    takeLatest(TechStacksActions.UPDATE_SUCCESS, onGetTechStacks),
    takeLatest(TechStacksActions.DELETE_SUCCESS, onGetTechStacks),
  ]);
}
