import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_VACATION_REVIEWERS_SUCCESS = createAction('vacations/GET_VACATION_REVIEWERS.SUCCESS');
export const getVacationReviewers = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: '/vacations/reviewers',
    });
    dispatch(GET_VACATION_REVIEWERS_SUCCESS(data));
  } catch (err) {}
};

export const GET_TODAY_VACATIONS_SUCCESS = createAction('vacations/GET_TODAY_VACATIONS.SUCCESS');
export const getTodayVacations = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: '/vacations/today',
    });
    dispatch(GET_TODAY_VACATIONS_SUCCESS(data));
  } catch (err) {}
};

export const GET_MY_VACATIONS_SUCCESS = createAction('vacations/GET_MY_VACATIONS.SUCCESS');
export const GET_MY_VACATIONS_PENDING = createAction('vacations/GET_MY_VACATIONS.PENDING');
export const GET_MY_VACATIONS_FAILURE = createAction('vacations/GET_MY_VACATIONS.FAILURE');
export const getMyVacations =
  (year = '', type = '') =>
  async (dispatch) => {
    dispatch(GET_MY_VACATIONS_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `/vacations/my`,
        qsParams: {
          year: year || null,
          type: type || null,
        },
      });
      dispatch(GET_MY_VACATIONS_SUCCESS(data));
    } catch (err) {
      dispatch(GET_MY_VACATIONS_FAILURE(err));
    }
  };
export const getMyVacationsForMonth =
  (month = '', type = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `/vacations/my-month`,
        qsParams: {
          year: month || null,
          type: type || null,
        },
      });
      dispatch(GET_MY_VACATIONS_SUCCESS(data));
    } catch (err) {}
  };

export const GET_ALL_VACATIONS_PENDING = createAction('vacations/GET_ALL_VACATIONS.PENDING');
export const GET_ALL_VACATIONS_SUCCESS = createAction('vacations/GET_ALL_VACATIONS.SUCCESS');
export const GET_ALL_VACATIONS_ERROR = createAction('vacations/GET_ALL_VACATIONS.ERROR');

export const getAllVacations =
  ({ status = '', year = '', month = '', type = '', requesterName = '', onFailure }) =>
  async (dispatch) => {
    dispatch(GET_ALL_VACATIONS_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: '/vacations',
        qsParams: {
          status: status || null,
          year: year || null,
          month: month || null,
          type: type || null,
          requesterName: requesterName || null,
        },
      });
      dispatch(GET_ALL_VACATIONS_SUCCESS(data));
    } catch (error) {
      dispatch(GET_ALL_VACATIONS_ERROR(error?.message));
      onFailure(error?.message);
    }
  };

export const GET_VACATIONS_BY_REVIEWER_SUCCESS = createAction('vacations/GET_VACATIONS_BY_REVIEWER.SUCCESS');
export const getVacationsByReviewer = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `/vacations/review-vacation`,
    });
    dispatch(GET_VACATIONS_BY_REVIEWER_SUCCESS(data));
  } catch (err) {}
};

export const CHANGE_CONTEXT = createAction('vacations/CHANGE_CONTEXT');
export const changeContext = (context) => (dispatch) => {
  dispatch(CHANGE_CONTEXT(context));
};

export const CREATE_VACATION_SUCCESS = createAction('vacations/CREATE_VACATION.SUCCESS');
export const CREATE_VACATION_FAILURE = createAction('vacations/CREATE_VACATION.FAILURE');
export const createVacation = (vacationData, onSuccess, onFailure, context) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: '/vacations/',
      query: vacationData,
    });
    dispatch(CREATE_VACATION_SUCCESS({ data, context }));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_VACATION_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const UPDATE_VACATION_SUCCESS = createAction('vacations/UPDATE_VACATION.SUCCESS');
export const UPDATE_VACATION_FAILURE = createAction('vacations/UPDATE_VACATION.FAILURE');
export const updateVacation = (vacationId, newData, onSuccess, onFailure, context) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `/vacations/${vacationId}`,
      query: newData,
    });
    dispatch(CREATE_VACATION_SUCCESS({ data, context }));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_VACATION_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CHANGE_VACATION_STATUS_SUCCESS = createAction('vacations/CHANGE_VACATION_STATUS.SUCCESS');
export const CHANGE_VACATION_STATUS_FAILURE = createAction('vacations/CHANGE_VACATION_STATUS.FAILURE');
export const changeVacationStatus = (vacationId, status, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `/vacations/status/${vacationId}`,
      query: { status },
    });
    dispatch(CHANGE_VACATION_STATUS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CHANGE_VACATION_STATUS_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CHANGE_VACATION_REVIEWERS_SUCCESS = createAction('vacations/CHANGE_VACATION_REVIEWERS.SUCCESS');
export const CHANGE_VACATION_REVIEWERS_FAILURE = createAction('vacations/CHANGE_VACATION_REVIEWERS.FAILURE');
export const changeVacationReviewers = (vacationId, newData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `/vacations/reviewers/${vacationId}`,
      query: newData,
    });
    dispatch(CHANGE_VACATION_REVIEWERS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CHANGE_VACATION_REVIEWERS_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_VACATION_SUCCESS = createAction('vacations/DELETE_VACATION.SUCCESS');
export const DELETE_VACATION_FAILURE = createAction('vacations/DELETE_VACATION.FAILURE');
export const deleteVacation = (vacationId, onSuccess, onFailure, context) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `/vacations/${vacationId}`,
    });
    dispatch(CREATE_VACATION_SUCCESS({ data, context }));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_VACATION_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};
export const CHECK_VACATIONS_TO_REVIEW = createAction('vacations/CHECK_VACATIONS_TO_REVIEW');
export const checkVacationsToReview = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: 'vacations/review-vacation',
      method: 'GET',
    });
    dispatch(CHECK_VACATIONS_TO_REVIEW(data));
  } catch (err) {}
};
export const SET_VACATIONS_TO_REVIEW = createAction('vacations/SET_VACATIONS_TO_REVIEW');
export const setVacationsToReview = (hasVacation) => (dispatch) => {
  dispatch(SET_VACATIONS_TO_REVIEW(hasVacation));
};
