import { store } from '../../../index';
import { getMyVacations, checkVacationsToReview, getAllVacations } from '../../../actions/vacations';
import { Context } from 'types/vacations';

export function* onVacationUpdate(action) {
  const { dispatch } = store;
  const context = action.payload?.context;

  dispatch(checkVacationsToReview());

  if (context === Context.MY) {
    dispatch(getMyVacations());
  }

  if (context === Context.ALL) {
    dispatch(getAllVacations({}));
  }

  yield true;
}
