import { createAction } from '@reduxjs/toolkit';
import { ApiService } from 'Redux/middleware/api';
import { columnStatus } from '../../assets/projectColumnStatus';

export const CREATE_COLUMN_SUCCESS = createAction('projectBoardColumn/CREATE_COLUMN.SUCCESS');
export const CREATE_COLUMN_FAILURE = createAction('projectBoardColumn/CREATE_COLUMN.FAILURE');
export const createColumn = (columnData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: '/projects/board/columns',
      query: columnData,
    });

    dispatch(CREATE_COLUMN_SUCCESS(data));
    onSuccess(columnStatus.ADDED);
  } catch (error) {
    dispatch(CREATE_COLUMN_FAILURE(error));
    onFailure(error?.response?.data?.error);
  }
};

export const UPDATE_COLUMN_SUCCESS = createAction('projectBoardColumn/UPDATE_COLUMN.SUCCESS');
export const UPDATE_COLUMN_FAILURE = createAction('projectBoardColumn/UPDATE_COLUMN.FAILURE');
export const updateColumn = (columnData, columnId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `projects/board/columns/${columnId}`,
      query: columnData,
    });

    dispatch(UPDATE_COLUMN_SUCCESS(data));
    onSuccess(columnStatus.UPDATED);
  } catch (error) {
    dispatch(UPDATE_COLUMN_FAILURE(error));
    onFailure(error?.response?.data?.err);
  }
};

export const DELETE_COLUMN_SUCCESS = createAction('projectBoardColumn/DELETE_COLUMN.SUCCESS');
export const DELETE_COLUMN_FAILURE = createAction('prpjectBoardColumn/DELETE_COLUMN.FAILURE');
export const deleteColumn = (columnId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `projects/board/columns/${columnId}`,
    });

    dispatch(DELETE_COLUMN_SUCCESS(data));
    onSuccess(columnStatus.DELETED);
  } catch (error) {
    dispatch(DELETE_COLUMN_FAILURE(error));
    onFailure(error?.response?.data?.err);
  }
};
