import { createReducer } from '@reduxjs/toolkit';
import {
  GET_STATIC_TASK_BY_ID_FAILURE,
  GET_STATIC_TASK_BY_ID_PENDING,
  GET_STATIC_TASK_BY_ID_SUCCESS,
  RESET_STATISTICS_FILTERS,
  SET_PREV_PROJECT_ID,
  SET_STATISTICS_FILTERS,
} from 'Redux/actions/taskStatisctis';
import { DateRangeType, IntervalType, TimeFormat } from 'types/projects';

export interface ColumnData {
  title: string;
  totalTime: number;
  tasksCount: number;
  averageTime: number;
}

export interface StatisticsFilters {
  selectedColumns: string[];
  selectedInterval: IntervalType;
  selectedTimeFormat: TimeFormat;
  dateRange: DateRangeType;
}

export interface ColumnStatistics {
  [key: string]: ColumnData;
}

export interface StatisticsByColumnName {
  status: string;
  data: ColumnStatistics;
}

export interface TaskStatisticsState {
  statisticsByColumnName: ColumnStatistics | null;
  isLoading: boolean;
  isError: boolean;
  prevProjectId: string | null;
  filters: StatisticsFilters;
}

const initialState: TaskStatisticsState = {
  statisticsByColumnName: null,
  isLoading: false,
  isError: false,
  prevProjectId: null,
  filters: {
    selectedColumns: [],
    selectedInterval: 'month',
    selectedTimeFormat: 'days',
    dateRange: {
      startDate: null,
      endDate: null,
    },
  },
};

const handlers = {
  [GET_STATIC_TASK_BY_ID_SUCCESS as any]: (
    prevState: TaskStatisticsState,
    { payload }: { payload: StatisticsByColumnName },
  ) => {
    return {
      ...prevState,
      statisticsByColumnName: payload.data,
      isLoading: false,
      isError: false,
    };
  },
  [GET_STATIC_TASK_BY_ID_FAILURE as any]: (prevState: TaskStatisticsState) => {
    return {
      ...prevState,
      isError: true,
      isLoading: false,
      statisticsByColumnName: null,
    };
  },
  [GET_STATIC_TASK_BY_ID_PENDING as any]: (prevState: TaskStatisticsState) => {
    return {
      ...prevState,
      isLoading: true,
      isError: false,
      statisticsByColumnName: null,
    };
  },
  [SET_STATISTICS_FILTERS as any]: (
    prevState: TaskStatisticsState,
    { payload }: { payload: Partial<StatisticsFilters> },
  ) => ({
    ...prevState,
    filters: {
      ...prevState.filters,
      ...payload,
    },
  }),

  [RESET_STATISTICS_FILTERS.type]: (prevState: TaskStatisticsState) => ({
    ...prevState,
    filters: initialState.filters,
    statisticsByColumnName: null,
  }),

  [SET_PREV_PROJECT_ID.type]: (prevState: TaskStatisticsState, { payload }: { payload: string }) => ({
    ...prevState,
    prevProjectId: payload,
  }),
};

export default createReducer(initialState, handlers);
