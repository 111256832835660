export const font = {
  fontFamily: '"Montserrat", sans-serif',
};

export const colors = {
  greyColor: '#6F6F70',
  purpleColor: '#3f51b5',
  white: 'rgb(255, 255, 255)',
};

export const taskStatisticsColors = {
  totalAverageColor: 'rgb(255, 159, 64)',
  daysColor: 'rgb(54, 162, 235)',
  hoursColor: 'rgb(75, 192, 192)',
  minutesColor: 'rgb(153, 102, 255)',
};
