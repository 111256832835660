import { createAction, Dispatch } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import { StatisticsFilters } from 'Redux/reducers/taskStatistics';

export const GET_STATIC_TASK_BY_ID_SUCCESS = createAction('taskStatistic/GET_STATIC_TASK_BY_ID.SUCCESS');
export const GET_STATIC_TASK_BY_ID_FAILURE = createAction('taskStatistic/GET_STATIC_TASK_BY_ID.FAILURE');
export const GET_STATIC_TASK_BY_ID_PENDING = createAction('taskStatistic/GET_STATIC_TASK_BY_ID.PENDING');

export type GetStaticTaskByIdPayload = {
  projectId: string;
  startDate: string | null;
  endDate: string | null;
};

export const getTaskStatisticsByColumnName =
  ({ projectId, startDate, endDate }: GetStaticTaskByIdPayload) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(GET_STATIC_TASK_BY_ID_PENDING());
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `/task-statistics/by-column-name/${projectId}`,
        qsParams: {
          startDate,
          endDate,
        },
      });
      dispatch(GET_STATIC_TASK_BY_ID_SUCCESS(data));
    } catch (err: any) {
      dispatch(GET_STATIC_TASK_BY_ID_FAILURE(err));
    }
  };

export const SET_STATISTICS_FILTERS = createAction('taskStatistic/SET_STATISTICS_FILTERS');
export const setStatisticsFilters = (filters: Partial<StatisticsFilters>) => ({
  type: SET_STATISTICS_FILTERS,
  payload: filters,
});

export const RESET_STATISTICS_FILTERS = createAction('taskStatistic/RESET_STATISTICS_FILTERS');
export const SET_PREV_PROJECT_ID = createAction('taskStatistic/SET_PREV_PROJECT_ID');
