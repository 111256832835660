import { takeLatest } from 'redux-saga/effects';

import {
  CHANGE_VACATION_REVIEWERS_SUCCESS,
  CHANGE_VACATION_STATUS_SUCCESS,
  CREATE_VACATION_SUCCESS,
  DELETE_VACATION_SUCCESS,
  UPDATE_VACATION_SUCCESS,
} from '../../../actions/vacations';
import { onChangeVacationStatus } from './onChangeVacationStatus';
import { onVacationUpdate } from './onVacationUpdate';

export default function* fn() {
  yield takeLatest(CREATE_VACATION_SUCCESS, onVacationUpdate);
  yield takeLatest(UPDATE_VACATION_SUCCESS, onVacationUpdate);
  yield takeLatest(DELETE_VACATION_SUCCESS, onVacationUpdate);

  yield takeLatest(CHANGE_VACATION_STATUS_SUCCESS, onChangeVacationStatus);
  yield takeLatest(CHANGE_VACATION_STATUS_SUCCESS, onVacationUpdate);

  yield takeLatest(CHANGE_VACATION_REVIEWERS_SUCCESS, onVacationUpdate);
}
