import { createReducer } from '@reduxjs/toolkit';

import { Loading } from 'types/common';
import {
  CHANGE_CONTEXT,
  CHECK_VACATIONS_TO_REVIEW,
  GET_ALL_VACATIONS_ERROR,
  GET_ALL_VACATIONS_PENDING,
  GET_ALL_VACATIONS_SUCCESS,
  GET_MY_VACATIONS_FAILURE,
  GET_MY_VACATIONS_PENDING,
  GET_MY_VACATIONS_SUCCESS,
  GET_TODAY_VACATIONS_SUCCESS,
  GET_VACATION_REVIEWERS_SUCCESS,
  GET_VACATIONS_BY_REVIEWER_SUCCESS,
} from '../actions/vacations';
import { Context } from 'types/vacations';

const initialState = {
  context: Context.MY,
  vacationReviewers: [],
  myVacations: [],
  myVacationsLoading: Loading.IDLE,
  myVacationsError: null,
  allVacations: null,
  allVacationLoading: Loading.IDLE,
  allVacationsError: null,
  vacationRequests: [],
  todayVacations: [],
  hasVactionsToReview: false,
};

const handlers = {
  [GET_VACATION_REVIEWERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacationReviewers: payload,
    };
  },

  [GET_TODAY_VACATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      todayVacations: payload,
    };
  },

  [GET_MY_VACATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      myVacations: payload,
      myVacationsLoading: Loading.SUCCESS,
    };
  },
  [GET_MY_VACATIONS_PENDING]: (prevState) => {
    return {
      ...prevState,
      myVacationsLoading: Loading.PENDING,
    };
  },
  [GET_MY_VACATIONS_FAILURE]: (prevState, { error }) => {
    return {
      ...prevState,
      myVacationsLoading: Loading.FAILURE,
      myVacationsError: error,
    };
  },

  [GET_ALL_VACATIONS_PENDING]: (prevState) => {
    return {
      ...prevState,
      allVacationLoading: Loading.PENDING,
    };
  },

  [GET_ALL_VACATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      allVacations: payload?.length ? payload : null,
      allVacationLoading: Loading.SUCCESS,
    };
  },

  [GET_ALL_VACATIONS_ERROR]: (prevState, { error }) => {
    return {
      ...prevState,
      allVacationsError: error,
      allVacationLoading: Loading.FAILURE,
    };
  },

  [GET_VACATIONS_BY_REVIEWER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacationRequests: payload,
    };
  },
  [CHECK_VACATIONS_TO_REVIEW]: (prevState, { payload }) => {
    return {
      ...prevState,
      hasVactionsToReview: payload,
    };
  },
  [CHANGE_CONTEXT]: (prevState, { payload }) => {
    return {
      ...prevState,
      context: payload,
    };
  },
};

export default createReducer(initialState, handlers);
