import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_SELECTED_PROJECT,
  CONNECT_TO_SLACK,
  CREATE_PROJECT_SUCCESS,
  EDIT_PROJECT_SUCCESS,
  GET_ACTIVE_PROJECTS_MY_SUCCESS,
  GET_ACTIVE_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_PROJECT_BY_CODE_FAILURE,
  GET_PROJECT_BY_CODE_SUCCESS,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECTS_BY_USER_SUCCESS,
  SET_PROJECT_LOADING,
  TOGGLE_PROJECT_AS_STARRED,
} from '../actions/projects';

const initialState = {
  projects: [],
  projectsByUser: [],
  starredProjects: null,
  selectedProject: null,
  activeProjects: null,
  activeProjectsMy: null,
  error: null,
  slackUrl: null,
  isLoading: false,
};

const handlers = {
  [GET_ALL_PROJECTS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projects: payload,
    };
  },

  [GET_PROJECTS_BY_USER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectsByUser: payload,
    };
  },
  [GET_PROJECT_BY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedProject: payload,
    };
  },
  [CLEAR_SELECTED_PROJECT]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedProject: null,
    };
  },

  [GET_PROJECT_BY_CODE_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedProject: payload,
    };
  },

  [GET_PROJECT_BY_CODE_FAILURE]: (prevState) => {
    return {
      ...prevState,
      selectedProject: null,
    };
  },

  [CREATE_PROJECT_SUCCESS]: (prevState, { payload }) => {
    const allProjects = [...prevState.projects];
    const newProjects = [payload, ...allProjects];
    return {
      ...prevState,
      projects: newProjects,
    };
  },

  [EDIT_PROJECT_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedProject: payload.data,
    };
  },

  [GET_ACTIVE_PROJECTS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      activeProjects: payload,
    };
  },

  [GET_ACTIVE_PROJECTS_MY_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      activeProjectsMy: payload,
    };
  },

  [TOGGLE_PROJECT_AS_STARRED]: (prevState, { payload }) => {
    const allStarredProjects = [...prevState.starredProjects];
    const newAllStarredProjects = [payload, ...allStarredProjects];
    return {
      ...prevState,
      starredProjects: newAllStarredProjects,
    };
  },

  [CONNECT_TO_SLACK]: (prevState, { payload }) => {
    return {
      ...prevState,
      slackUrl: payload.link,
    };
  },

  [SET_PROJECT_LOADING]: (prevState, { payload }) => {
    return {
      ...prevState,
      isLoading: payload,
    };
  },
};

export default createReducer(initialState, handlers);
