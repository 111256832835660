import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import users from './users';
import reports from './reports';
import projects from './projects';
import projectTasks from './projectTasks';
import timeLogs from './timeLogs';
import holidays from './holidays';
import projectMilestones from './projectMilestones';
import docbooks from './docbooks';
import docpages from './docpages';
import vacations from './vacations';
import userComments from './userComments';
import projectComments from './projectComments';
import userReviews from './userReviews';
import userReminders from './userReminders';
import vacancies from './vacancies';
import specializations from './specializations';
import candidateChannel from './candidateChannel';
import statistics from './statistics';
import filters from './filters';
import history from './history';
import projectSprints from './projectSprints';
import projectVersions from './projectVersions';
import tasksComments from './tasksComments';
import taskHistory from './taskHistory';
import tasksCheckList from './tasksCheckList';
import taskStatistics from './taskStatistics';
import techStacks from './techStacks.reducers';

const rootReducer = combineReducers({
  auth,
  users,
  reports,
  projects,
  projectTasks,
  timeLogs,
  holidays,
  projectMilestones,
  docbooks,
  docpages,
  vacations,
  userComments,
  projectComments,
  userReviews,
  userReminders,
  vacancies,
  specializations,
  candidateChannel,
  statistics,
  filters,
  history,
  tasksComments,
  projectSprints,
  projectVersions,
  taskHistory,
  tasksCheckList,
  taskStatistics,
  techStacks,
});

export default rootReducer;
