import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import { Dispatch } from 'redux';

const endpoint = 'techstacks';

export interface ITechStack {
  _id: string;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
}

type ErrorHandler = {
  message: string | null;
};

export const TechStacksActions = {
  GET_ALL_SUCCESS: createAction<ITechStack[]>('techstacks/GET_ALL_TECHSTACK.SUCCESS'),
  GET_ALL_FAILURE: createAction<ErrorHandler>('techstacks/GET_ALL_TECHSTACK.FAILURE'),
  CREATE_SUCCESS: createAction<ITechStack>('techstacks/CREATE_TECHSTACK.SUCCESS'),
  CREATE_FAILURE: createAction<ErrorHandler>('techstacks/CREATE_TECHSTACK.FAILURE'),
  UPDATE_SUCCESS: createAction<ITechStack>('techstacks/UPDATE_TECHSTACK.SUCCESS'),
  UPDATE_FAILURE: createAction<ErrorHandler>('techstacks/UPDATE_TECHSTACK.FAILURE'),
  DELETE_SUCCESS: createAction('techstacks/DELETE_TECHSTACK.SUCCESS'),
  DELETE_FAILURE: createAction<ErrorHandler>('techstacks/DELETE_TECHSTACK.FAILURE'),
};

export class TechStacksHandlers {
  static getAll =
    (onSuccess?: (data: ITechStack[]) => void, onFailure?: (error?: string) => void) => async (dispatch: Dispatch) => {
      try {
        const { data } = await ApiService.apiCall({ method: 'GET', endpoint });
        dispatch(TechStacksActions.GET_ALL_SUCCESS(data));
        onSuccess?.(data);
      } catch (err: any) {
        dispatch(TechStacksActions.GET_ALL_FAILURE(err));
        onFailure?.(err?.response?.data?.error);
      }
    };

  static create =
    (techStack: ITechStack, onSuccess?: (data: ITechStack) => void, onFailure?: (error?: string) => void) =>
    async (dispatch: Dispatch) => {
      try {
        const { data } = await ApiService.apiCall({ method: 'POST', endpoint, query: techStack });
        dispatch(TechStacksActions.CREATE_SUCCESS(data));
        onSuccess?.(data);
      } catch (err: any) {
        dispatch(TechStacksActions.CREATE_FAILURE(err));
        onFailure?.(err?.response?.data?.error);
      }
    };

  static update =
    (id: string, techStack: ITechStack, onSuccess?: (data: ITechStack) => void, onFailure?: (error?: string) => void) =>
    async (dispatch: Dispatch) => {
      try {
        const { data } = await ApiService.apiCall({ method: 'PATCH', endpoint: `${endpoint}/${id}`, query: techStack });
        dispatch(TechStacksActions.UPDATE_SUCCESS(data));
        onSuccess?.(data);
      } catch (err: any) {
        dispatch(TechStacksActions.UPDATE_FAILURE(err));
        onFailure?.(err?.response?.data?.error);
      }
    };

  static delete =
    (id: string, onSuccess?: () => void, onFailure?: (error?: string) => void) => async (dispatch: Dispatch) => {
      try {
        await ApiService.apiCall({ method: 'DELETE', endpoint: `${endpoint}/${id}` });
        dispatch(TechStacksActions.DELETE_SUCCESS());
        onSuccess?.();
      } catch (err: any) {
        dispatch(TechStacksActions.DELETE_FAILURE(err));
        onFailure?.(err?.response?.data?.error);
      }
    };
}
