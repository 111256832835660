export interface IObjectAny {
  [key: string]: any;
}

export enum Loading {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
