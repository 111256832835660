import axios from 'axios';
import { api as apiConfig } from '../configs/api';
import i18n from 'i18next';
import { tokenStorage } from '../../Helpers/storageFunctions';

export const ApiService = {
  apiCall: ({ endpoint = '', method = 'GET', query = {}, headers = {}, qsParams = {}, signal = undefined }) => {
    const api = axios.create({
      baseURL: apiConfig.defaultUrl,
      headers: headers || {},
      params: qsParams || {},
    });

    api.interceptors.request.use(async (config) => {
      const accessToken = tokenStorage().getAccessToken();
      config.headers['Accept-Language'] = i18n.language;

      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    });

    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status !== 401) return Promise.reject(error);

        try {
          const refreshToken = tokenStorage().getRefreshToken();

          if (!refreshToken) {
            return Promise.reject(error);
          }

          const res = await axios.post(
            'auth/refresh',
            { refreshToken: refreshToken },
            { baseURL: apiConfig.defaultUrl },
          );
          tokenStorage().setAccessToken(res.data.accessToken);
          tokenStorage().setRefreshToken(res.data.refreshToken);
          return api.request(error.config);
        } catch (err) {
          // 404 - if refresh token is not found in mongoDB
          if (err.response?.status === 404 || err.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('lastVisited', window.location.pathname);
            window.location.replace('');
          } else {
            return Promise.reject(error);
          }
        }
      },
    );

    switch (method) {
      case 'GET':
        return api.get(endpoint, { signal });
      case 'POST':
        return api.post(endpoint, query, { signal });
      case 'PATCH':
        return api.patch(endpoint, query, { signal });
      case 'PUT':
        return api.put(endpoint, query, { signal });
      case 'DELETE':
        return api.delete(endpoint, query, { signal });
      default:
        return api.get(endpoint, query, { signal });
    }
  },
};
