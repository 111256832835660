import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';
import type { Dispatch } from 'redux';

export const GET_CHECKLIST_FOR_TASKS_SUCCESS = createAction('projectCheckList/GET_CHECKLIST_FOR_TASKS.SUCCESS');
export const getCheckListForTask = (tasksId: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await ApiService.apiCall({ method: 'GET', endpoint: `task-checklists/${tasksId}` });
    dispatch(GET_CHECKLIST_FOR_TASKS_SUCCESS(data));
  } catch (error) {}
};

export const CREATE_CHECKLIST_FOR_TASKS_SUCCESS = createAction('projectCheckList/CREATE_CHECKLIST_FOR_TASKS.SUCCESS');
export const CREATE_CHECKLIST_FOR_TASKS_FAILURE = createAction('projectCheclList/CREATE_CHECKLIST_FOR_TASK.FAILURE');
export const createCheckListForTask =
  (
    projectId: string,
    tasksId: string,
    name: string,
    onSuccessSubmitCheckList: () => void,
    onFailureCheckList: (error: any) => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `task-checklists`,
        query: {
          projectId,
          tasksId,
          name,
        },
      });
      dispatch(CREATE_CHECKLIST_FOR_TASKS_SUCCESS(data));
      onSuccessSubmitCheckList();
    } catch (error: any) {
      dispatch(CREATE_CHECKLIST_FOR_TASKS_FAILURE(error));
      onFailureCheckList(error?.response?.data?.error);
    }
  };

export const DELETE_CHECKLIST_FOR_TASKS_SUCCESS = createAction('projectCheckList/DELETE_CHECKLIST_FOR_TASKS.SUCCESS');
export const DELETE_CHECKLIST_FOR_TASKS_FAILURE = createAction('projectCheckList/DELETE_CHECKLIST_FOR_TASKS.FAILURE');
export const deleteCheckListForTask =
  (checklistId: string, onSuccessDeleteChecklist: () => void, onFailureCheckList: (error: any) => void) =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `task-checklists/${checklistId}`,
      });
      dispatch(DELETE_CHECKLIST_FOR_TASKS_SUCCESS(data));
      onSuccessDeleteChecklist();
    } catch (error: any) {
      dispatch(DELETE_CHECKLIST_FOR_TASKS_FAILURE(error));
      onFailureCheckList(error?.response?.data?.error);
    }
  };

export const ADD_CHECKITEM_SUCCESS = createAction('projectCheckList/ADD_CHECKITEM.SUCCESS');
export const ADD_CHECKITEM_FAILURE = createAction('projectCheckList/ADD_CHECKITEM.FAILURE');
export const addCheckItemForChecklist =
  (checklistId: string, name: string, onSuccessAddCheckItem: () => void, onFailureAddCheckItem: (error: any) => void) =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `task-checklists/item`,
        query: { checklistId, name },
      });
      dispatch(ADD_CHECKITEM_SUCCESS(data));
      onSuccessAddCheckItem();
    } catch (error: any) {
      dispatch(ADD_CHECKITEM_FAILURE(error));
      onFailureAddCheckItem(error?.response?.data?.error);
    }
  };

export const UPDATE_CHECKITEM_SUCCESS = createAction('projectCheckList/UPDATE_CHECKITEM.SUCCESS');
export const UPDATE_CHECKITEM_FAILURE = createAction('projectCheckList/UPDATE_CHECKITEM.FAILURE');
export const updateCheckItemStatus =
  (checklistId: string, checkItemId: string, isCompleted: boolean) => async (dispatch: Dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PATCH',
        endpoint: `task-checklists/${checklistId}/items/${checkItemId}/status`,
        query: { isCompleted },
      });
      dispatch(UPDATE_CHECKITEM_SUCCESS(data));
    } catch (error: any) {
      dispatch(UPDATE_CHECKITEM_FAILURE(error));
    }
  };

export const DELETE_CHECKITEM_SUCCESS = createAction('projectCheckList/DELETE_CHECKITEM.SUCCESS');
export const DELETE_CHECKITEM_FAILURE = createAction('projectCheckList/DELETE_CHECKITEM.FAILURE');
export const deleteCheckItem =
  (
    checklistId: string,
    checkItemId: string,
    onSuccessDeleteCheckItem: () => void,
    onFailureCheckList: (error: any) => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `task-checklists/${checklistId}/items/${checkItemId}`,
      });
      dispatch(DELETE_CHECKITEM_SUCCESS(data));
      onSuccessDeleteCheckItem();
    } catch (error: any) {
      dispatch(DELETE_CHECKITEM_FAILURE(error));
      onFailureCheckList(error?.response?.data?.error);
    }
  };

export const CLEAR_CHECKLIST_FOR_TASKS = createAction('projectCheckList/CLEAR_CHECKLIST_FOR_TASKS');
export const clearChecklistForTasks = () => async (dispatch: Dispatch) => {
  dispatch(CLEAR_CHECKLIST_FOR_TASKS());
};
