import { additionRole, roles } from '../assets/roles';
import { adminRoutes, commonRoutes, contractorRoutes, profileRoute, salesAccEmplRoutes } from '../assets/routes';

const { OWNER, ADMIN, MANAGER, RECRUITER, HR, SALES, EMPLOYEE, CONTRACTOR, PARTNER, CANDIDATE, ACCOUNTANT } = roles;
const { LEAD } = additionRole;
export const recruitmentAndHRRoutes = ['statistics', 'candidats', 'reminders-all', 'vacancies'];
export const ReportsRoutes = ['reports', 'billabilityReports', 'benchReports'];

class RolePermissionsHelper {
  canAddUser(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
      case RECRUITER:
      case HR:
        return true;
      default:
        return false;
    }
  }

  canEditUser(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
      case RECRUITER:
      case HR:
        return true;
      default:
        return false;
    }
  }

  canChangeCandidateStatus(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, RECRUITER, HR, MANAGER, SALES, EMPLOYEE].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canEditProfile(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, RECRUITER, HR, ACCOUNTANT, MANAGER, SALES, EMPLOYEE].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    return rolesWithPermission || additionRolesWithPermission ? true : false;
  }

  canEditCandidate(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, RECRUITER, HR, MANAGER, SALES, EMPLOYEE].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canResetPassword(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
        return true;
      default:
        return false;
    }
  }

  canFireUser(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
      case RECRUITER:
      case HR:
        return true;
      default:
        return false;
    }
  }

  canFireYourself(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
        return true;
      default:
        return false;
    }
  }

  canChangeUserRoleTo(userRole, additionRole = []) {
    if ([OWNER, ADMIN].includes(userRole)) {
      return [ADMIN, MANAGER, RECRUITER, HR, SALES, EMPLOYEE, CONTRACTOR, PARTNER, ACCOUNTANT];
    }
    if ([RECRUITER, HR].includes(userRole) || !!additionRole.filter((role) => [LEAD].indexOf(role) > -1).length) {
      return [MANAGER, RECRUITER, HR, SALES, EMPLOYEE, CONTRACTOR, PARTNER, ACCOUNTANT];
    } else {
      return [];
    }
  }

  canViewGrades(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, RECRUITER, HR, SALES, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canEditGrades(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
      case RECRUITER:
      case HR:
        return true;
      default:
        return false;
    }
  }

  canViewSalary(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
      case RECRUITER:
      case HR:
        return true;
      default:
        return false;
    }
  }

  canViewCandidates(userRole) {
    switch (userRole) {
      case OWNER:
      case ADMIN:
      case RECRUITER:
      case HR:
        return true;
      default:
        return false;
    }
  }

  canViewUserComments(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, RECRUITER, HR, SALES, MANAGER, EMPLOYEE, ACCOUNTANT].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canAddDocs(userRole) {
    const rolesWithPermission = [ADMIN, HR, RECRUITER, OWNER];
    return rolesWithPermission.includes(userRole);
  }

  canDeleteDocs(userRole) {
    const rolesWithPermission = [ADMIN, HR, RECRUITER, OWNER];
    return rolesWithPermission.includes(userRole);
  }

  canEditDocs(userRole) {
    const rolesWithPermission = [ADMIN, HR, RECRUITER, OWNER];
    return rolesWithPermission.includes(userRole);
  }

  canViewPrivateDocs(userRole) {
    const rolesWithPermission = [ADMIN, HR, RECRUITER, OWNER, SALES];
    return rolesWithPermission.includes(userRole);
  }

  canSeeProjectMembers(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, RECRUITER, HR, SALES, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canViewVacationMenu({ userRole, userAdditionalRole, userId, vacationUserId }) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => userAdditionalRole.indexOf(role) > -1).length;
    const isCurrentUserVacation = userId === vacationUserId;

    if (rolesWithPermission || additionRolesWithPermission || isCurrentUserVacation) {
      return true;
    } else {
      return false;
    }
  }

  canEditAllStatuses(userRole, userAdditionalRole) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => userAdditionalRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canAddProjectMembers(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;

    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canAddProject(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;

    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    }
    return true;
  }

  canChangeAdditionRole(userRole) {
    const rolesWithPermission = [OWNER, ADMIN, HR, RECRUITER].includes(userRole);
    if (!rolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canEditProject(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canViewAllProjects(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER, HR].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canEditProjectComment(userRole) {
    const rolesWithPermission = [OWNER, ADMIN].includes(userRole);
    if (!rolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canEditUserFields(userRole, additionRole = []) {
    return userRole !== CANDIDATE && userRole !== EMPLOYEE && !additionRole.includes(ADMIN);
  }

  canSeeHRInformation(userRole) {
    const rolesWithPermission = [OWNER, ADMIN, HR, RECRUITER];
    return rolesWithPermission.includes(userRole);
  }

  canEditHolidays(userRole) {
    const rolesWithPermission = [ADMIN, OWNER, MANAGER, HR, RECRUITER];
    return rolesWithPermission.includes(userRole);
  }

  canTrackTimeForUser(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canSeeAllDocBooks(userRole, additionRole = []) {
    const rolesWithPermission = [
      OWNER,
      ADMIN,
      MANAGER,
      RECRUITER,
      HR,
      SALES,
      EMPLOYEE,
      CONTRACTOR,
      ACCOUNTANT,
    ].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canAddDocBookAdmin(userRole) {
    const rolesWithPermission = [OWNER, ADMIN];
    return rolesWithPermission.includes(userRole);
  }

  canCreateDocBook(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER, RECRUITER, HR, SALES, EMPLOYEE, ACCOUNTANT].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canArchiveDocBook(userRole) {
    const rolesWithPermission = [OWNER, ADMIN];
    return rolesWithPermission.includes(userRole);
  }

  canEditOrArchiveDocBookPage(userRole) {
    const rolesWithPermission = [OWNER, ADMIN];
    return rolesWithPermission.includes(userRole);
  }

  canSeeVacationsRequests(userRole, additionRole = []) {
    const rolesWithPermission = [MANAGER, ADMIN, OWNER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canAccessReminders(userRole) {
    return [ADMIN, OWNER, HR, RECRUITER].includes(userRole);
  }

  canAddTask(userRole) {
    const rolesWithPermission = [OWNER, ADMIN];
    return rolesWithPermission.includes(userRole);
  }

  canEditBudget(userRole) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER];
    return rolesWithPermission.includes(userRole);
  }

  canAddProjectCharters(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canChangeStatus(userRole) {
    const rolesWithPermission = [CANDIDATE];
    return rolesWithPermission.includes(userRole);
  }

  isOwner(userRole) {
    return userRole === OWNER;
  }

  canEditWorklogs(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canSeeFilterInVacationStatistics(userRole, additionRole = []) {
    const rolesWithPermission = [ADMIN, MANAGER, HR].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canSeeVacancyDetails(userRole, additionRole = []) {
    const rolesWithPermission = [OWNER, ADMIN, MANAGER, RECRUITER, HR].includes(userRole);
    const additionRolesWithPermission = !![LEAD].filter((role) => additionRole.indexOf(role) > -1).length;
    if (!rolesWithPermission && !additionRolesWithPermission) {
      return false;
    } else {
      return true;
    }
  }

  canDeleteItemsFromStatistics(userRole) {
    const rolesWithPermission = [ADMIN];
    return userRole ? rolesWithPermission.includes(userRole) : false;
  }

  canAccessSections(userRole, additionRole = []) {
    const baseSections = [
      'dashboard',
      'timetracking',
      'projects',
      'users',
      'candidats',
      'wiki',
      'statistics',
      'vacations',
      'reports',
      'billabilityReports',
      'benchReports',
    ];

    const adminSections = ['vacancies', 'reminders'];
    const hrSections = ['vacancies', 'reminders'];
    const recruiterSections = ['vacancies', 'reminders'];
    const managerSections = ['vacancies'];
    const employeeSections = [];
    const accountantSections = [];
    const salesSections = [];
    const contractorSections = ['timetracking', 'projects', 'reports', 'vacations'];
    const candidateSections = [];
    const partnerSections = ['/profile/:type'];

    const roleSections = {
      [OWNER]: [...baseSections, ...adminSections],
      [ADMIN]: [...baseSections, ...adminSections],
      [HR]: [...baseSections, ...hrSections],
      [RECRUITER]: [...baseSections, ...recruiterSections],
      [MANAGER]: [...baseSections, ...managerSections],
      [EMPLOYEE]: [...baseSections, ...employeeSections],
      [ACCOUNTANT]: [...baseSections, ...accountantSections],
      [SALES]: [...baseSections, ...salesSections],
      [CONTRACTOR]: [...contractorSections],
      [CANDIDATE]: [...candidateSections],
      [PARTNER]: [...partnerSections],
    };

    const additionalRoleSections = ['vacancies'];

    let sections = roleSections[userRole] || baseSections;

    if (additionRole.includes(LEAD)) {
      sections = [...new Set([...sections, ...additionalRoleSections])];
    }

    return sections;
  }

  canAccessRoutes(userRole, additionRole = []) {
    if (
      !!additionRole.filter((role) => [LEAD].indexOf(role) > -1).length ||
      [OWNER, ADMIN, RECRUITER, MANAGER, HR].includes(userRole)
    ) {
      return adminRoutes;
    }

    if ([SALES, ACCOUNTANT, EMPLOYEE].includes(userRole)) {
      return salesAccEmplRoutes;
    }

    if ([CONTRACTOR].includes(userRole)) {
      return [...contractorRoutes, ...profileRoute];
    }

    if ([CANDIDATE, PARTNER].includes(userRole)) {
      return [...profileRoute];
    } else {
      return commonRoutes;
    }
  }

  canShowBirthdays(userRole) {
    switch (userRole) {
      case OWNER:
      case PARTNER:
      case CANDIDATE:
        return false;
      default:
        return true;
    }
  }

  canBeAssignedToRecruiter(userRole) {
    return userRole === CANDIDATE;
  }

  canViewTrialUser(userRole, additionRole = []) {
    const allowedRoles = [OWNER, ADMIN, RECRUITER, MANAGER, HR];
    return additionRole.includes(LEAD) || allowedRoles.includes(userRole);
  }

  canAccessStatistics(userRole, additionRole = []) {
    return additionRole.includes(LEAD) || [OWNER, ADMIN, SALES].includes(userRole);
  }

  canViewGlobalSearch(userRole) {
    return userRole !== roles.CONTRACTOR;
  }
}

export const RolePermissions = new RolePermissionsHelper();
