import { createReducer } from '@reduxjs/toolkit';
import { ITechStack, TechStacksActions } from '../actions/techStacks.action';

type InitialStateType = {
  techStacks: ITechStack[];
  error: string | null;
};

const initialState: InitialStateType = {
  techStacks: [],
  error: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(TechStacksActions.GET_ALL_SUCCESS, (state, { payload }) => {
      state.techStacks = payload;
    })
    .addCase(TechStacksActions.GET_ALL_FAILURE, (state, { payload }) => {
      state.error = payload.message;
    })
    .addCase(TechStacksActions.CREATE_SUCCESS, (state, { payload }) => {
      state.techStacks.unshift(payload);
    })
    .addCase(TechStacksActions.CREATE_FAILURE, (state, { payload }) => {
      state.error = payload.message;
    })
    .addCase(TechStacksActions.UPDATE_SUCCESS, (state, { payload }) => {
      state.techStacks = state.techStacks.map((stack) => (stack._id === payload._id ? payload : stack));
    })
    .addCase(TechStacksActions.UPDATE_FAILURE, (state, { payload }) => {
      state.error = payload.message;
    })
    .addCase(TechStacksActions.DELETE_SUCCESS, (state, { payload }) => {
      state.techStacks = state.techStacks.filter((stack) => stack._id !== payload);
    })
    .addCase(TechStacksActions.DELETE_FAILURE, (state, { payload }) => {
      state.error = payload.message;
    });
});
