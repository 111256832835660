import { IUser } from './users';

export enum VacationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export enum Context {
  MY = 'my',
  ALL = 'all',
}

export interface IVacation {
  _id: string;
  status: VacationStatus;
  startDate: string;
  endDate: string;
  type: string;
  comment: string;
  days: number;
}

export interface TodayVacation extends IVacation {
  reviewers: string[];
  user: IUser;
}

export interface MyVacation extends IVacation {
  reviewers: IUser[];
  user: string;
}

export interface AllVacation extends IVacation {
  reviewers: IUser[];
  user: IUser[];
}

export interface VacationRequest extends IVacation {
  reviewers: string[];
  user: IUser[];
}
