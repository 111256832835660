import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import initLocalization from './i18n';

import { getCurrentUser, getUserAdditionRole, getUserRole } from './Redux/selectors/auth';

import Loading from './shared/loading';
import Sidebar from './components/navigations-section/sidebar';
import PrivateRoute from './components/private-route';
import { RolePermissions } from './Helpers/RolePermissions';
import { fridayTimelogStatus, tokenStorage } from './Helpers/storageFunctions';
import { format, getDay } from 'date-fns';
import { setFridaySubmitStatus } from './Redux/actions/timeLogs';
import { fetchCurrentUser } from './Redux/actions/auth';
import NotificationProvider from './components/notification/notificationContext';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme/theme';

import './App.scss';

initLocalization();

const App = ({ role, setFridaySubmit }) => {
  const dispatch = useDispatch();
  const userAdditionRole = useSelector(getUserAdditionRole);
  const [startRoute] = RolePermissions.canAccessRoutes(role, userAdditionRole);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  useEffect(() => {
    const { status, date } =
      fridayTimelogStatus().getStatus() ?? fridayTimelogStatus().setStatus(false) ?? fridayTimelogStatus().getStatus();
    if (status && format(new Date(), 'yyyy-MM-dd') !== date && getDay(new Date()) === 5) {
      setFridaySubmit(true);
      fridayTimelogStatus().setStatus(false);
    }
    if (!status && getDay(new Date()) === 5) {
      setFridaySubmit(true);
    }
    if (getDay(new Date()) !== 5) {
      fridayTimelogStatus().setStatus(false);
    }
  }, [setFridaySubmit]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <div className='App'>
            <Suspense fallback={<Loading />}>
              <NotificationProvider>
                {currentUser && (
                  <>
                    <Sidebar>
                      <main className='content-container'>
                        <Switch>
                          <PrivateRoute
                            exact
                            path={['/project/:projectCode/:type/:taskNumber', '/project/:projectCode/:type']}
                            component={lazy(() => import('./containers/project-overview'))}
                          />
                          <PrivateRoute
                            exact
                            path='/dashboard'
                            component={lazy(() => import('./containers/dashboard'))}
                          />
                          <PrivateRoute
                            exact
                            path='/projects'
                            component={lazy(() => import('./containers/projects'))}
                          />
                          {/* <PrivateRoute exact path='/profile' component={lazy(() => import('./containers/profile'))} /> */}
                          <PrivateRoute
                            exact
                            path='/profile/:type'
                            component={lazy(() => import('./containers/user-overview'))}
                          />
                          <PrivateRoute exact path='/users' component={lazy(() => import('./containers/teams'))} />
                          <PrivateRoute
                            exact
                            path='/user/:id/:type'
                            component={lazy(() => import('./containers/user-overview'))}
                          />
                          <PrivateRoute
                            exact
                            path='/billabilityReports'
                            component={lazy(() => import('./containers/billabilityReports'))}
                          />
                          <PrivateRoute
                            exact
                            path='/benchReports'
                            component={lazy(() => import('./containers/bench-statistics'))}
                          />
                          <PrivateRoute
                            exact
                            path='/vacancies'
                            component={lazy(() => import('./containers/vacancies'))}
                          />
                          <PrivateRoute
                            exact
                            path='/vacancy/:id/:type'
                            component={lazy(() => import('./containers/vacancy-overview'))}
                          />
                          <PrivateRoute
                            exact
                            path='/candidats/:type'
                            component={lazy(() => import('./containers/candidates-overview'))}
                          />
                          <PrivateRoute
                            exact
                            path={['/timetracking', '/timetracking/:projectCode/:taskNumber']}
                            component={lazy(() => import('./containers/timetracking'))}
                          />
                          <PrivateRoute exact path='/reports' component={lazy(() => import('./containers/reports'))} />
                          <PrivateRoute exact path='/wiki/:type' component={lazy(() => import('./containers/wiki'))} />
                          <PrivateRoute
                            exact
                            path='/docbook/:id'
                            component={lazy(() => import('./containers/docbookpage'))}
                          />
                          <PrivateRoute
                            exact
                            path='/docpage/:id'
                            component={lazy(() => import('./containers/docpage'))}
                          />
                          <PrivateRoute
                            exact
                            path='/:action-docpage/:id'
                            component={lazy(() => import('./containers/docpage-create'))}
                          />
                          <PrivateRoute
                            exact
                            path='/docpage-history/:id'
                            component={lazy(() => import('./containers/docpage-history'))}
                          />
                          <PrivateRoute
                            exact
                            path='/vacations/:type'
                            component={lazy(() => import('./containers/vacations'))}
                          />
                          <PrivateRoute
                            exact
                            path='/reminders-all'
                            component={lazy(() => import('./containers/reminders'))}
                          />
                          <PrivateRoute
                            exact
                            path='/requests/:type'
                            component={lazy(() => import('./containers/vacancies'))}
                          />
                          <PrivateRoute
                            exact
                            path='/statistics'
                            component={lazy(() => import('./containers/hr-statistics'))}
                          />
                          {currentUser &&
                            (currentUser.role === 'CANDIDATE' || currentUser.role === 'PARTNER' ? (
                              <Redirect to='profile/details' />
                            ) : (
                              <Redirect to={startRoute} />
                            ))}
                        </Switch>
                      </main>
                    </Sidebar>
                  </>
                )}

                {!currentUser && !tokenStorage().getAccessToken() && (
                  <Switch>
                    <Route path='/' exact component={lazy(() => import('./containers/login'))} />
                    <Route path='/resetpassword' exact component={lazy(() => import('./containers/reset-password'))} />
                    <Route
                      path='/reset-password/:token'
                      exact
                      component={lazy(() => import('./containers/reset-password-by-email'))}
                    />
                    <Redirect to='/' />
                  </Switch>
                )}
              </NotificationProvider>
            </Suspense>
          </div>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state) => ({
  role: getUserRole(state),
});

const mapDispatchToProps = {
  setFridaySubmit: setFridaySubmitStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
