import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_PROJECT_TASKS_SUCCESS = createAction('projects/GET_ALL_PROJECT_TASKS.SUCCESS');
export const GET_ALL_FILTERED_PROJECT_TASKS_SUCCESS = createAction('projects/GET_ALL_FILTERED_PROJECT_TASKS.SUCCESS');

let abortFetchAllProjectTasks = null;

export const fetchAllProjectTasks =
  (projectId, filters = {}) =>
  async (dispatch) => {
    if (abortFetchAllProjectTasks) abortFetchAllProjectTasks.abort();
    abortFetchAllProjectTasks = new AbortController();

    try {
      const { signal } = abortFetchAllProjectTasks;
      const {
        filterDescription = '',
        filterMilestone = '',
        filterAssignee = '',
        filterSprint = '',
        filterVersion = '',
      } = filters;

      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `tasks/project/${projectId}`,
        signal,
        qsParams: {
          description: filterDescription || null,
          milestoneId: filterMilestone || null,
          assignedTo: filterAssignee || null,
          sprintId: filterSprint || null,
          versionId: filterVersion || null,
        },
      });

      const isFiltered = filterDescription || filterMilestone || filterAssignee || filterSprint || filterVersion;

      if (isFiltered) {
        dispatch(GET_ALL_FILTERED_PROJECT_TASKS_SUCCESS({ data }));
      } else {
        dispatch(
          GET_ALL_PROJECT_TASKS_SUCCESS({
            data,
            filters,
          }),
        );
      }
    } catch (err) {}
  };

export const GET_ALL_BACKLOG_TASKS_SUCCESS = createAction('projects/GET_ALL_BACKLOG_TASKS.SUCCESS');
export const fetchAllBacklogTasks =
  (projectId, backlogId, filters = {}) =>
  async (dispatch) => {
    const { filterDescription = '', filterMilestone = '' } = filters;
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `tasks/project/${projectId}`,
        qsParams: {
          backlogId,
          description: filterDescription,
          milestoneId: filterMilestone,
        },
      });

      dispatch(GET_ALL_BACKLOG_TASKS_SUCCESS({ data, filters }));
    } catch (err) {}
  };

export const GET_TASK_BY_ID_SUCCESS = createAction('projects/GET_TASK_BY_ID.SUCCESS');
export const fetchTaskById = (taskId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `tasks/${taskId}`,
    });
    dispatch(GET_TASK_BY_ID_SUCCESS(data));
  } catch (err) {}
};

export const GET_TASK_BY_NUMBER_SUCCESS = createAction('projects/GET_TASK_BY_NUMBER_SUCCESS');
export const fetchTaskByNumber = (params) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `tasks/task/${params.taskNumber}/${params.projectCode}`,
    });
    dispatch(GET_TASK_BY_NUMBER_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_PROJECT_TASK_SUCCESS = createAction('projects/CREATE_PROJECT_TASK.SUCCESS');
export const CREATE_PROJECT_TASK_FAILURE = createAction('projects/CREATE_PROJECT_TASK.FAILURE');
export const createProjectTask =
  (
    {
      projectId,
      columnId,
      title,
      description,
      assignedTo,
      budget,
      isBillable,
      isTemplate,
      specialType,
      priority,
      milestoneId,
      sprintId,
      versionId,
      lastTaskPosition,
    },
    onSuccess,
    onFailure,
  ) =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'tasks',
        query: {
          projectId,
          columnId,
          title,
          description,
          assignedTo,
          budget,
          isBillable,
          isTemplate,
          specialType,
          priority,
          milestoneId,
          sprintId,
          versionId,
          lastTaskPosition,
        },
      });
      dispatch(CREATE_PROJECT_TASK_SUCCESS(data));
      onSuccess();
    } catch (err) {
      dispatch(CREATE_PROJECT_TASK_FAILURE());
      onFailure(err?.response?.data?.error);
    }
  };

export const UPDATE_PROJECT_TASKS_AFTER_TIMELOG_SUCCESS = createAction(
  'projects/UPDATE_PROJECT_TASKS_AFTER_TIMELOG.SUCCESS',
);
export const updateProjectTasksAfterTimelog = (data) => (dispatch) => {
  try {
    dispatch(UPDATE_PROJECT_TASKS_AFTER_TIMELOG_SUCCESS(data));
  } catch (err) {}
};

export const UPDATE_BACKLOG_TASKS_AFTER_TIMELOG_SUCCESS = createAction(
  'projects/UPDATE_BACKLOG_TASKS_AFTER_TIMELOG.SUCCESS',
);
export const updateBacklogTasksAfterTimelog = (data) => async (dispatch) => {
  try {
    dispatch(UPDATE_BACKLOG_TASKS_AFTER_TIMELOG_SUCCESS(data));
  } catch (err) {}
};

export const UPDATE_COLUMN_ORDER = createAction('projects/UPDATE_COLUMN_ORDER');
export const SORT_PROJECT_TASKS = createAction('projects/SORT_PROJECT_TASKS');
export const EDIT_PROJECT_TASK_FAILURE = createAction('projects/EDIT_PROJECT_TASK.FAILURE');
export const EDIT_PROJECT_TASK_SUCCESS = createAction('projects/EDIT_PROJECT_TASK.SUCCESS');
export const editProjectTask = (projectTaskData, editId, onSuccess, onFailure, filters) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `tasks/${editId}`,
      method: 'PUT',
      query: projectTaskData,
    });
    onSuccess();
    dispatch(EDIT_PROJECT_TASK_SUCCESS({ data, filters }));
  } catch (err) {
    dispatch(EDIT_PROJECT_TASK_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_PROJECT_TASK_SUCCESS = createAction('projects/DELETE_PROJECT_TASK.SUCCESS');
export const DELETE_PROJECT_TASK_FAILURE = createAction('projects/DELETE_PROJECT_TASK.FAILURE');
export const deleteProjectTask = (taskId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `tasks/${taskId}`,
      method: 'DELETE',
    });
    dispatch(DELETE_PROJECT_TASK_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_PROJECT_TASK_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CLEAR_FILTERED_TASKS = createAction('projects/CLEAR_FILTERED_TASKS');
export const clearFilteredTasks = () => (dispatch) => dispatch(CLEAR_FILTERED_TASKS());

export const CLEAR_PROJECT_TASKS = createAction('projects/CLEAR_PROJECT_TASKS');
export const clearProjectTasks = () => (dispatch) => dispatch(CLEAR_PROJECT_TASKS());

export const CLEAR_CURRENT_TASK = createAction('projects/CLEAR_CURRENT_TASK');
export const clearCurrentTask = () => (dispatch) => dispatch(CLEAR_CURRENT_TASK());

export const CREATE_TASK_POSITIONS_SUCCESS = createAction('projects/CREATE_TASK_POSITIONS_SUCCESS');
export const createTasksPositionAction = (positionData, onSuccess, onFailure) => async (dispatch) => {
  try {
    await ApiService.apiCall({
      endpoint: 'tasks/positionNull',
      method: 'PUT',
      query: positionData,
    });

    onSuccess();
    dispatch(CREATE_TASK_POSITIONS_SUCCESS());
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};

export const uploadImageProjectTask = (file, onFailure) => async (dispatch) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `tasks/uploadImage`,
      method: 'POST',
      query: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};

export const deleteImageProjectTask = (imageUrl, onFailure) => async (dispatch) => {
  try {
    await ApiService.apiCall({
      endpoint: `tasks/taskImg?imageUrl=${encodeURIComponent(imageUrl)}`,
      method: 'DELETE',
    });
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};
